import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "twin.macro";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";
import { SEO } from "../components";

const intro = `
At The Ear Place we pride ourselves on our high level of service and thorough testing procedures.
<br /> <br />
Our advanced hearing assessment includes further checks to provide additional information on both the health and function of the ears. This data is important to ensure each individual is getting the best possible treatment and advice.
`;

const HearingAssessment = () => {
  return (
    <>
      <SEO title="Hearing Assessment" />
      <BlankPageNoImage
        header="HEARING ASSESSMENT"
        intro={intro}
        ariaLabel="HEARING ASSESSMENT"
      >
        <ProseWrapper>
          <StaticImage
            src="../images/hearing-assessment-diagram.jpg"
            alt="Diagram of the ear"
            quality={90}
          />
          <div>
            <h4>The hearing assessment</h4>

            <p>
              A hearing assessment will establish the quietest sounds that a
              person is able to hear at several different frequencies. Sounds
              are played through headphones in 2 different ways which allows our
              audiologist to establish whether there is a hearing loss and if
              so, what type of hearing loss it is and where the damage has
              occurred. The first part of the hearing test is done via ‘air
              conduction’, this is where our headphones are placed over the ears
              and sounds are played via the route that all sounds are heard on a
              daily basis.
            </p>

            <p>
              - The sound travels down the ear canal
              <br />- Hits the ear drum causing the ear drum to move/vibrate
              <br />- This causes the ossicles (three little bones) in the
              middle ear to move
              <br />- This causes a ripple effect in the fluid of the inner ear
              which in turn makes very little hair cells move
              <br />- Signals from these hair cells are then sent to the brain
              <br />- Depending on the strength of the ‘ripple’ from the
              incoming sound depends on which hair cells are stimulated and each
              hair cells are responsible for different frequencies we hear
              <br />- Testing the hearing via air conduction tests the whole
              hearing system working together as one
            </p>

            <p>
              The second part of the hearing test is done via ‘bone conduction’,
              this is where a different type of headset is placed onto the
              prominent bony section of the skull behind one ear. The same type
              of sounds are played again but this time the sound travels
              directly through the bone of the skull to the inner ear. This way
              the audiologist can test how well the inner section of the ear is
              working in isolation even if the middle or outer section isn’t
              working as well as it should. When both tests are completed, by
              comparing them the audiologist is able to establish what type of
              hearing loss may be present. Hearing loss can be categorised into
              3 categories:
            </p>

            <h4>Sensorineural hearing loss</h4>
            <p>
              This type of hearing loss is where the damage her been done to the
              sensory organ of hearing, the inner ear or beyond. This is a very
              common type of hearing loss and happens to everybody to some
              extent over time due to general noise exposure and the aging
              process.
            </p>

            <p>
              Generally, with this type of hearing loss the high frequencies
              will be the first to deteriorate which affects clarity. An
              individual with this type of hearing loss will still be able to
              hear certain sounds quite easily – for this reason it is sometimes
              difficult to know that a hearing loss is developing because some
              parts of the hearing system and certain sounds remain unaffected.
              When the higher frequencies deteriorate speech will be the most
              affected making it sound as though people might ‘mumble’ and
              situations where there is a background noise will become
              increasingly difficult.
            </p>

            <h4>Conductive hearing loss</h4>
            <p>
              This type of hearing loss is where there is a problem
              ‘mechanically’ with the sound getting through to the inner ear and
              beyond. The inner ear may be perfectly healthy and fully
              functioning but there may be a transmission problem. Examples of
              things that would cause this type of hearing loss include but are
              not limited to; wax blockages, a hole in the ear drum, a cold
              causing a fluid build-up behind the ear drum or stiffening of the
              middle ear bones.
            </p>
            <h4>Mixed hearing loss</h4>
            <p>
              This is where a person may have a mixture of the above hearing
              losses.
            </p>
            <p>
              Regardless of the type of hearing loss our audiologists will offer
              their advice and expertise on the best thing options and routes
              available. This may be an onward referral to another medical
              professional, or it may be to discuss options and to offer advice
              on hearing aids and assistive listening devices.
            </p>
          </div>
        </ProseWrapper>
      </BlankPageNoImage>
      <LocationBlock />
    </>
  );
};

export default HearingAssessment;
